<template>
  <b-modal
    ref="importIssuance"
    :ok-title="$t('importIssuanceModal.button.ok')"
    :cancel-title="$t('importIssuanceModal.button.cancel')"
    ok-variant="primary"
    cancel-variant="default"
    :ok-disabled="uploadDisabled || !importEnabled"
    @ok="handleSubmit"
    @hide="clearData"
    @close="clearData"
    @cancel="clearData"
  >
    <div slot="modal-title">
      <i class="ion ion-ios-person-add" />
      {{ $t('importIssuanceModal.title') }}
    </div>
    <div
      id="Import-issuance"
      class="row"
    >
      <div class="col">
        <div
          v-if="![400, 404, 304, 500].includes(importIssuanceProgress.statusCode) && Object.keys(importIssuanceProgress).length
            && ![StatusImportIssuanceConst.PROCESSING,StatusImportIssuanceConst.NO_IMPORT_IN_PROGRESS].includes(importIssuanceProgress.status)"
          v-html="getLastImportIssuanceProgressStatus()"
        />
        <div v-if="isInProgress">
          <b-progress
            v-if="Object.keys(importIssuanceProgress).length"
            :value="importIssuanceProgress.processedPages"
            :max="importIssuanceProgress.totalPages"
            show-progress
            animated
            variant="warning"
          />
        </div>
        <br>
        <div class="form-group">
          <label class="form-label mb-3">
            {{ $t('importIssuanceModal.label.update') }}
          </label>
          <div class="file-uploader-container">
            <div
              v-if="isInProgress"
              class="overlay-import-investors"
            >
              <div class="overlay-spinner">
                <b-spinner label="Spinning" />
              </div>
              <div class="overlay-text">
                {{ getOverlayTitle() }}
              </div>
            </div>
            <cp-file-uploader
              id="import-issuance-uploader"
              extension=".csv"
              :use-secure="true"
              @onChange="fileUploaded"
              @fileLoading="fileLoading"
            />
          </div>
        </div>
        <div
          v-if="summary"
          class="summary"
        >
          <h4>Preliminary check</h4>
          <div class="summary-totals">
            <span><strong>Total:</strong> {{ summary.totalRecords }}</span>
            <span><strong>Errors:</strong> {{ summary.recordsWithErrors.length }}</span>
          </div>

          <div v-if="summary.recordsWithErrors.length>0">
            <strong>List of errors:</strong>
            <div class="summary-errors-list">
              <span
                v-for="(errorRecord, errorIndex) in summary.recordsWithErrors"
                :key="errorIndex"
              >
                <strong>Line {{ errorRecord.lineNumber }}: </strong>{{ getListOfErrors(errorRecord.errors) }}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>
              <strong style="margin-right: 0.25rem">{{ $t('importIssuanceModal.label.note') }}</strong>
              <span>{{ $t('importIssuanceModal.noteDescription') }}</span>
            </p>
            <p><a href="/file-samples/import-issuance.csv">{{ $t('importIssuanceModal.button.downloadSample') }}</a></p>
            <div class="d-flex align-items-center">
              <cp-switcher
                name="forceIssuanceToTBE"
                :value="forceIssuanceToTBE"
                @input="value => forceIssuanceToTBE = value"
              />
              <div class="ml-2">
                {{ $t('importIssuanceModal.forceTBEIssuance') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="importEnabled && !validating"
        id="overlay-import-btn"
        class="overlay-import-btn"
      >
        <b-tooltip
          target="overlay-import-btn"
          triggers="hover"
        >
          {{ $t('importIssuanceModal.overlayTitle') }}
        </b-tooltip>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import { StatusImportIssuance } from './constants';
import CpSwitcher from '~/components/common/standalone-components/inputs/cp-switch';


export default {
  name: 'ImportIssuanceModal',
  components: {
    CpFileUploader,
    CpSwitcher,
  },
  data() {
    return {
      fileData: {},
      StatusImportIssuanceConst: StatusImportIssuance,
      importEnabled: false,
      isInProgress: false,
      summary: null,
      validating: false,
      forceIssuanceToTBE: false,
    };
  },
  computed: {
    ...mapGetters('importIssuance', ['importIssuanceProgress', 'isModalOpen', 'isSendingCsv']),
    uploadDisabled() {
      return !Object.keys(this.fileData).length || !this.fileData.file.accepted;
    },
  },
  watch: {
    isModalOpen: {
      immediate: true,
      handler(currentState) {
        if (currentState) {
          const { idIssuer: issuerId, tokenId } = this.$route.params;
          this.getImportProgressStatus(issuerId, tokenId);
          this.summary = null;
          this.validating = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('importIssuance', ['getImportIssuanceProgress', 'setIsModalOpen', 'cleanImportIssuanceProgress', 'setIsSendingCsv', 'validateImport']),
    fileLoading() {
      this.summary = null;
    },
    fileUploaded(fileData) {
      this.fileData = fileData;
      if (fileData.fileKey) {
        this.summary = null;
        this.validating = true;

        const { idIssuer: issuerId, tokenId } = this.$route.params;
        this.validateImport({
          issuerId,
          tokenId,
          fileKey: fileData.fileKey,

        }).then((summary) => {
          this.summary = summary;
        }).catch(() => {
          this.validating = false;
        });
      }
    },
    handleSubmit(env) {
      env.preventDefault();
      this.setIsSendingCsv(true);
      this.$emit('onImport', { ...this.fileData, forceIssuanceToTBE: this.forceIssuanceToTBE });
    },
    clearData() {
      this.fileData = {};
      this.setIsModalOpen(false);
      this.cleanImportIssuanceProgress();
    },
    getOverlayTitle() {
      if (this.isSendingCsv) {
        return this.$t('importIssuanceModal.sendingFile');
      }
      return (this.importIssuanceProgress.percentageProgress) ? this.$t('importIssuanceModal.overlayTitle') : this.$t('importIssuanceModal.checkingState');
    },
    async getImportProgressStatus(issuerId, tokenId, interval = 5000) {
      const execute = async () => {
        try {
          await this.getImportIssuanceProgress({ issuerId, tokenId });

          this.checkInProgress();
          this.canImport();

          if (this.importIssuanceProgress.status === StatusImportIssuance.COMPLETED_SUCCESSFULLY
            || this.importIssuanceProgress.status === StatusImportIssuance.COMPLETED_WITH_ERRORS
            || !this.isModalOpen
            || this.importIssuanceProgress.message === StatusImportIssuance.NO_IMPORT_IN_PROGRESS) {
            return;
          }
          setTimeout(execute, interval);
        } catch (error) {
          this.$log.error('Something went wrong in get import issuance progress:', error);
        }
      };
      await execute();
    },
    getLastImportIssuanceProgressStatus() {
      let textStatus = 'Last import has been ';
      let icon = '';

      if (this.importIssuanceProgress.status === StatusImportIssuance.COMPLETED_SUCCESSFULLY) {
        textStatus += `<strong>${StatusImportIssuance.COMPLETED_SUCCESSFULLY }</strong>`;
       icon = '<span class="ion ion-md-checkmark-circle text-success"></span>';
      } else {
        textStatus += `<strong>${StatusImportIssuance.COMPLETED_WITH_ERRORS }</strong>`;
        icon = '<span class="ion ion-md-alert text-danger"></span>';
      }
      textStatus += ` on the ${moment(this.importIssuanceProgress.lastProcessDate).format('DD/MM/YYYY hh:mm')}`;

      return `${icon} ${textStatus}`;
    },
    shouldAllowImport() {
      // We prevent blocking the import issuance by checking the status codes of the request triggered and in case something goes wrong we enable the import
      // also we decide when enable or disable the import feature by checking the current status, like PROCESSING,  of the import issuance in progress.
      return ![400, 404, 304, 500].includes(this.importIssuanceProgress.statusCode) && (!this.importIssuanceProgress.status || this.importIssuanceProgress.status === this.StatusImportIssuanceConst.PROCESSING);
    },
    checkInProgress() {
      this.isInProgress = this.importIssuanceProgress?.status === this.StatusImportIssuanceConst.PROCESSING;
    },
    canImport() {
      this.importEnabled = this.importIssuanceProgress?.status === this.StatusImportIssuanceConst.COMPLETED_SUCCESSFULLY
          || this.importIssuanceProgress?.status === this.StatusImportIssuanceConst.COMPLETED_WITH_ERRORS;
    },
    getListOfErrors(errorMessages) {
      return errorMessages.map(this.decodeErrorMessage).join('\n');
    },
    decodeErrorMessage(errorMessage) {
      switch (errorMessage) {
        case 'no-id': return this.$t('importIssuanceModal.summary.errors.noId');
        case 'invalid-assigned-tokens': return this.$t('importIssuanceModal.summary.errors.invalidAssignedTokens');
        case 'invalid-assigned-tokens-must-be-greater-than-zero': return this.$t('importIssuanceModal.summary.errors.invalidAssignedTokensZero');
        case 'invalid-price-per-unit': return this.$t('importIssuanceModal.summary.errors.invalidPricerPerUnit');
        case 'invalid-issuance-date': return this.$t('importIssuanceModal.summary.errors.invalidIssuanceDate');
        case 'not-matching-investor-for-id': return this.$t('importIssuanceModal.summary.errors.investorNotFound');
        case 'not-existing-investment-request-for-investor': return this.$t('importIssuanceModal.summary.errors.investorWithoutInvestmentRequest');
        case 'error-multiple-issuances-on-the-same-date': return this.$t('importIssuanceModal.summary.errors.errorMultipleIssuancesOnSameDate');
        case 'error-unexpected-amount-of-fields': return this.$t('importIssuanceModal.summary.errors.errorInvalidAmountOfFields');
        case 'investor-already-has-an-active-issuance': return this.$t('importIssuanceModal.summary.errors.investorAlreadyHasAnActiveIssuance');
        default: return '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.summary{
  .summary-totals{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  .summary-errors-list{
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-height: 10rem;
    overflow: hidden;
    overflow-y: scroll;
  }
}
</style>
