import { trim } from 'lodash/string';
import kebabToCamel from '~/utilities/convert-object-keys';

export default {
  data() {
    return {
      isEmptyExportList: false,
      urlParams: { issuerId: this.$route.params.idIssuer },
    };
  },
  watch: {
    $route({ params: { idIssuer: newIssuerId } }, { params: { idIssuer: oldIssuerId } }) {
      if (newIssuerId !== oldIssuerId) this.urlParams.issuerId = newIssuerId;
    },
  },
  methods: {
    getFullName(item) {
      return !item.name || trim(item.name) === '' ? item.email : item.name;
    },
    isLabels(item) {
      return (item.labels || []).length && item.labels.filter(val => val).length;
    },
    getLabelsList(item) {
      return (item.labels || []).filter(val => val).slice(0, 3);
    },
    getLabelsTitle(item) {
      return (item.labels || []).filter(val => val).slice(3).join('\n');
    },
    checkEmptyTable(totalItems) {
      this.isEmptyExportList = totalItems === 0;
    },
    getSelectedItems() {
      return this.$refs.cpTable.getSelectedItems();
    },
    getTableFilters() {
      const { limit, page, ...theRest } = this.$refs.cpTable.joinTableParams;
      if (theRest && theRest.labels) {
        theRest.labels = theRest.labels.split(',');
      }
      return kebabToCamel(theRest);
    },
    clearTableSelection() {
      this.$refs.cpTable.clearSelection();
    },
    updateTableData() {
      this.$refs.cpTable.updateTableData();
    },
  },
};
