<template>
  <div>
    <cp-button
      size
      variant="outline-info mr-3"
      @click="openModal"
    >
      <i class="ion ion-md-arrow-up" />
      {{ $t('investorsCommon.button.investor.import') }}
    </cp-button>

    <cp-import-investors
      ref="cpImportInvestors"
      @onImport="handleImport"
    />
    <cp-import-investors-errors-list
      ref="cpImportInvestorsErrorsList"
      :table-data="errorList"
      :error-message="errorMessage"
      :is-invalid-csv="isInvalidCsv"
    />
    <cp-confirm-modal
      ref="cpConfirmBulkImport"
      :title="$t('investorsCommon.button.investor.import')"
      ok-only
    >
      {{ $t('investorsCommon.button.investor.importBulkMsg', [this.importBulkInvestorsAmount]) }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CpImportInvestors from '~/pages/_idIssuer/investors/modals/import-investors-modal';
import CpImportInvestorsErrorsList from '~/pages/_idIssuer/investors/modals/import-investors-errors-list-modal';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'ImportInvestors',
  components: {
    CpImportInvestors,
    CpImportInvestorsErrorsList,
    CpButton,
    CpConfirmModal,
  },
  data() {
    return {
      errorList: [],
      errorName: '',
      errorMessage: '',
      importBulkInvestorsAmount: null,
    };
  },
  computed: {
    ...mapState('issuersInfo', ['currentToken']),
    isInvalidCsv() {
      return this.errorName === 'IMPORT_INVALID_CSV';
    },
    tokenId() {
      return this.$route.params.tokenId || this.currentToken.id;
    },
  },
  methods: {
    ...mapActions('investors', ['importList']),
    ...mapActions('importInvestors', ['setIsModalOpen', 'setIsSendingCsv']),
    openModal() {
      this.$refs.cpImportInvestors.$refs.importInvestors.show();
      this.setIsModalOpen(true);
    },
    handleImport({ fileKey }, triggerInvestmentEmail) {
      this.importList({
        issuerId: this.$route.params.idIssuer,
        body: {
          tokenId: this.tokenId,
          fileKey,
          triggerInvestmentEmail,
        },
      }).then(({ data }) => {
        this.errorList = [];
        this.errorName = '';
        if (data && data.investors) {
          this.importBulkInvestorsAmount = data.investors;
          this.$refs.cpConfirmBulkImport.show();
        }
        this.$emit('handleImportInvestors');
      }).catch(({ response }) => {
        const { data: errorData } = response;
        this.errorList = errorData.data || [];
        this.errorName = errorData.error;
        this.errorMessage = errorData.message;
        this.$refs.cpImportInvestorsErrorsList.$refs.ImportInvestorsErrorsList.show();
      }).finally(() => {
        this.$refs.cpImportInvestors.$refs.importInvestors.hide();
        this.setIsSendingCsv(false);
      });
    },
  },
};
</script>
