<template>
  <b-card
    class="mb-1"
    no-body
  >
    <b-card-header :class="noBorder ? 'no-border' : null">
      <a
        class="d-flex justify-content-between text-dark"
        :class="!isCollapsible ? 'no-collapse' : null"
        href="javascript:void(0)"
        :aria-expanded="isCollapsed"
        @click="collapse"
      >
        <div>
          <i :class="`ion ion-${titleIcon}`" />
          {{ title }}
        </div>
        <div
          v-if="isCollapsible"
          class="collapse-icon"
        />
      </a>
    </b-card-header>
    <b-collapse
      id="accordion2-2"
      :visible="visible || !isCollapsible"
      accordion="accordion2-2"
      @show="$emit('onExpand')"
      @hide="$emit('onCollapse')"
    >
      <slot />
    </b-collapse>
  </b-card>
</template>

<script>

export default {
  name: 'CpCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: '',
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isCollapsible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCollapsed: this.visible,
    };
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isCollapsed) => {
      if (collapseId === 'accordion2-2') {
        this.isCollapsed = isCollapsed;
      }
    });
  },
  methods: {
    collapse() {
      if (this.isCollapsible) {
        this.$root.$emit('bv::toggle::collapse', 'accordion2-2');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .no-border {
    border-bottom: transparent;
  }
  .no-collapse {
    cursor: default;
  }
</style>
