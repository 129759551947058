<template>
  <cp-card
    title-icon="ios-options"
    :title="$t('component.cpFilter.title')"
    :visible="visible"
    @onExpand="expand"
  >
    <cp-input-container
      v-model="filterData"
      :default-value="getDefaultValue"
      @edited="editHandler"
    >
      <div class="ui-bordered pt-4">
        <slot />
        <div
          v-if="isClearBtn"
          class="mb-4"
        >
          <b-btn
            variant="link"
            class="text-muted px-0 mt-1 clear-hover filterBlock text-left"
            @click="clearFilter"
          >
            <i class="ion ion-ios-refresh" />
            <span>{{ $t('component.cpFilter.button.filter.clear') }}</span>
          </b-btn>
        </div>
      </div>
    </cp-input-container>
  </cp-card>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpCard from '~/components/common/layouts/cp-card';

export default {
  name: 'CpFilter',
  components: {
    CpInputContainer,
    CpCard,
  },
  props: {
    isClearBtn: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filterData: {},
      clearFlag: false,
      visible: false,
    };
  },
  computed: {
    ...mapGetters('global', ['getFilterState']),
    getDefaultValue() {
      return this.getFilterState(this.name);
    },
  },
  created() {
    this.changeVisible();
    this.$store.subscribe((mutation) => {
      if ((mutation.payload || []).name === this.name) {
        this.filterData = this.getDefaultValue;
      }
    });
  },
  methods: {
    ...mapMutations('global', ['SET_FILTER_STATE']),
    expand() {
      this.visible = true;
      this.clearFlag = false;
    },
    changeVisible() {
      const data = this.getDefaultValue || this.filterData;
      this.visible = !!Object.keys(data).filter(
        key => key !== 'search' && (data[key] || data[key] === false),
      ).length;
    },
    clearFilter() {
      this.clearFlag = true;
      const clearData = {};
      Object.keys(this.getDefaultValue || this.filterData).forEach((key) => {
        clearData[key] = undefined;
      });

      this.filterData = clearData;
      this.updateStore();
      this.changeVisible();
      this.$emit('input', this.filterData);
    },
    updateStore() {
      const { name, filterData } = this;
      if (name) {
        this.SET_FILTER_STATE({ name, filterData });
      }
    },
    editHandler(isEdited) {
      if (isEdited && !this.clearFlag) {
        this.updateStore();
        this.$emit('input', this.filterData);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .filterBlock {
    min-width: auto;
    span {
     padding-left: 5px;
    }
  }
</style>
