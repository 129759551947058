<template>
  <b-modal
    ref="importInvestors"
    :ok-title="$t('importInvestorsModal.button.ok')"
    :cancel-title="$t('importInvestorsModal.button.cancel')"
    ok-variant="primary"
    cancel-variant="default"
    :ok-disabled="uploadDisabled"
    @ok="handleSubmit"
    @hide="clearData"
    @close="clearData"
    @cancel="clearData"
  >
    <div slot="modal-title">
      <i class="ion ion-ios-person-add" />
      {{ $t('importInvestorsModal.title') }}
    </div>
    <div
      id="Import-investors"
      class="row"
    >
      <div class="col">
        <div
          v-if="![400, 404, 304, 500].includes(importInvestorProgress.statusCode) && Object.keys(importInvestorProgress).length
            && ![StatusImportInvestorsConst.PROCESSING,StatusImportInvestorsConst.NO_IMPORT_IN_PROGRESS].includes(importInvestorProgress.status)"
          v-html="getLastImportInvestorProgressStatus()"
        />
        <div v-if="!Object.keys(importInvestorProgress).length || importInvestorProgress.status === StatusImportInvestorsConst.PROCESSING">
          <b-progress
            v-if="Object.keys(importInvestorProgress).length"
            :value="importInvestorProgress.investorsImported"
            :max="importInvestorProgress.totalInvestorsToImport"
            show-progress
            animated
            variant="warning"
          />
        </div>
        <br>
        <div class="form-group">
          <label class="form-label mb-3">
            {{ $t('importInvestorsModal.label.update') }}
          </label>
          <div class="file-uploader-container">
            <div
              v-if="shouldAllowImport()"
              class="overlay-import-investors"
            >
              <div class="overlay-spinner">
                <b-spinner label="Spinning" />
              </div>
              <div class="overlay-text">
                {{ getOverlayTitle() }}
              </div>
            </div>
            <cp-file-uploader
              id="import-investors-uploader"
              extension=".csv"
              :use-secure="true"
              @onChange="fileUploaded"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label
              class="form-label"
            >{{ $t('importInvestorsModal.switch.label') }}:
            </label>
          </div>
          <div class="col-2">
            <cp-switch
              v-model="isTriggerInvestmentEmail"
              name="isTriggerEmail"
              :disabled="shouldAllowImport()"
            />
          </div>
        </div>
      </div>
      <div
        v-if="shouldAllowImport()"
        id="overlay-import-btn"
        class="overlay-import-btn"
      >
        <b-tooltip
          target="overlay-import-btn"
          triggers="hover"
        >
          {{ $t('importInvestorsModal.overlayTitle') }}
        </b-tooltip>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import { CpSwitch } from '~/components/common/standalone-components/inputs';
import { StatusImportInvestors } from './constants';

export default {
  name: 'ImportInvestorsModal',
  components: {
    CpFileUploader,
    CpSwitch,
  },
  data() {
    return {
      fileData: {},
      isTriggerInvestmentEmail: false,
      StatusImportInvestorsConst: StatusImportInvestors,
    };
  },
  computed: {
    ...mapGetters('importInvestors', ['importInvestorProgress', 'isModalOpen', 'isSendingCsv']),
    uploadDisabled() {
      return !Object.keys(this.fileData).length || !this.fileData.file.accepted;
    },
  },
  watch: {
    isModalOpen: {
      immediate: true,
      handler(currentState) {
        if (currentState) {
          const { idIssuer: issuerId } = this.$route.params;
          this.getImportProgressStatus(issuerId);
        }
      },
    },
  },
  methods: {
    ...mapActions('importInvestors', ['getImportInvestorsProgress', 'setIsModalOpen', 'cleanImportInvestorsProgress', 'setIsSendingCsv']),
    fileUploaded(fileData) {
      this.fileData = fileData;
    },
    handleSubmit(env) {
      env.preventDefault();
      this.setIsSendingCsv(true);
      this.$emit('onImport', this.fileData, this.isTriggerInvestmentEmail);
    },
    clearData() {
      this.fileData = {};
      this.isTriggerInvestmentEmail = false;
      this.setIsModalOpen(false);
      this.cleanImportInvestorsProgress();
    },
    getOverlayTitle() {
      if (this.isSendingCsv) {
        return this.$t('importInvestorsModal.sendingFile');
      }
      return (this.importInvestorProgress.percentageProgress) ? this.$t('importInvestorsModal.overlayTitle') : this.$t('importInvestorsModal.checkingState');
    },
    async getImportProgressStatus(issuerId, interval = 3000) {
      const execute = async () => {
        try {
          await this.getImportInvestorsProgress(issuerId);

          if (this.importInvestorProgress.status === StatusImportInvestors.COMPLETED_SUCCESSFULLY
            || this.importInvestorProgress.status === StatusImportInvestors.COMPLETED_WITH_ERRORS
            || !this.isModalOpen
            || this.importInvestorProgress.message === StatusImportInvestors.NO_IMPORT_IN_PROGRESS) {
            return;
          }
          setTimeout(execute, interval);
        } catch (error) {
          this.$log.error('Something went wrong in get import investors progress:', error);
        }
      };
      await execute();
    },
    getLastImportInvestorProgressStatus() {
      let textStatus = 'Last import has been ';
      let icon = '';

      if (this.importInvestorProgress.status === StatusImportInvestors.COMPLETED_SUCCESSFULLY) {
        textStatus += `<strong>${StatusImportInvestors.COMPLETED_SUCCESSFULLY }</strong>`;
       icon = '<span class="ion ion-md-checkmark-circle text-success"></span>';
      } else {
        textStatus += `<strong>${StatusImportInvestors.COMPLETED_WITH_ERRORS }</strong>`;
        icon = '<span class="ion ion-md-alert text-danger"></span>';
      }
      textStatus += ` on the ${moment(this.importInvestorProgress.lastInvestorProcessedTime).format('DD/MM/YYYY hh:mm')}`;

      return `${icon} ${textStatus}`;
    },
    shouldAllowImport() {
      // We prevent blocking the import investors by checking the status codes of the request triggered and in case something goes wrong we enable the import
      // also we decide when enable or disable the import feature by checking the current status, like PROCESSING,  of the import investor in progress.
      return ![400, 404, 304, 500].includes(this.importInvestorProgress.statusCode) && (!this.importInvestorProgress.status || this.importInvestorProgress.status === this.StatusImportInvestorsConst.PROCESSING);
    },
  },
};
</script>
