import moment from 'moment';
import Cookie from 'js-cookie';
import CpSorting from '~/mixins/table-sorting';

import CpValidationFields from '~/mixins/validation-fields';

export default {
  data() {
    return {
      massSelect: false,
      deleteItem: {},
      isReady: false,
      resultFilterLabels: {},
      resultFilterPredefinedLabels: {},
      listSelectedInvest: [],
      errorField: false,
      tableFilters: {},
      hideCreateLabel: false,
      isCheckboxActive: false,
      modalId: 'edit-label-modal',
    };
  },
  mixins: [
    CpSorting,
    CpValidationFields,
  ],
  computed: {
    issuerLabelsOnly() {
      return this.labelsList.filter(label => !this.predefinedLabels.includes(label));
    },
    dateAndTimeExport() {
      return moment().format('YYYY-MM-DD, h-mm');
    },
    getCountryCodes() {
      return this.countries.map(({ name, countryCode }) => ({
        text: name,
        value: countryCode,
      }));
    },
    isSelectable() {
      return Boolean(this.listSelectedInvest.length || this.isCheckboxActive);
    },
  },
  created() {
    if (this.investorsType) {
      const { issuerId } = this.investorsType;
      this.getIssuerLabels({ issuerId }).finally(() => {
        Cookie.set('currentTypeInvestors', this.investorsType.view);
        this.isReady = true;
      });
    }
  },
  mounted() {
    this.tableFilters = this.$refs[this.filterRef].getActiveFilters();
  },
  methods: {
    // investors labels
    handleSearchLabel(q) {
      const { issuerId } = this.investorsType;
      this.getIssuerLabels({
        q,
        issuerId,
      }).then(() => {
        this.filterLabels(this.massSelect, this.issuerLabelsOnly);
      });
    },
    handleDeleteLabel(label) {
      this.deleteIssuerLabels({ issuerId: this.$route.params.idIssuer, label: label.labelName }).then(() => {
        this.$refs[this.tableRef].updateTableData();
        this.editInvestorsLabels();
      });
    },
    handleCreateLabel(label) {
      const validationResult = this.validationMandatoryFields(['label'], { label });
      if (!validationResult) {
        this.errorField = true;
        return;
      }
      this.CREATE_NEW_LABEL([label]);
      this.editInvestorsLabels();
    },
    getSelectedLabels(data) {
      const labelsNames = Object.keys(data);
      const labelsToAdd = labelsNames.filter(elem => data[elem].checked);
      const labelsToRemove = this.massSelect
        ? labelsNames.filter(elem => data[elem].checked === false)
        : labelsNames.filter(elem => !data[elem].checked && !data[elem].indeterminate);
      return {
        labelsToAdd,
        labelsToRemove,
      };
    },
    handleFilterLabels(data) {
      const labelsToFilter = Object.keys(data).filter(label => data[label].checked);

      if (labelsToFilter.length) {
        this.$set(this.tableFilters, 'labels', labelsToFilter.join());
      } else {
        this.$delete(this.tableFilters, 'labels');
      }
      this.$refs[this.tableRef].updateTableData();
      this.$nextTick(() => {
        this.$refs.CpLabelsModal.$refs.LabelsModal.hide();
      });
    },
    handleEditLabels(data) {
      const { selectedItems, excludedItems } = this.$refs[this.tableRef].getSelectedItems();
      const userIds = selectedItems.length ? selectedItems.map(({ id }) => id) : null;
      const exceptInvestorsIds = excludedItems.length ? excludedItems.map(({ id }) => id) : null;
      const batchLabelsFilters = this.$refs[this.tableRef].getTableFilters();
      if (!batchLabelsFilters.registrationSource) delete batchLabelsFilters.registrationSource;
      this.$refs.CpLabelsModal.disableOk();
      this.labelsBatchOperation({
        issuerId: this.investorsType.issuerId,
        body: {
          userIds,
          exceptInvestorsIds,
          ...this.getSelectedLabels(data),
          ...batchLabelsFilters,
        },
      }).finally(() => {
        this.resultFilterLabels = {};
        this.listSelectedInvest = [];
        this.selectAllCheckboxOff = !this.selectAllCheckboxOff;
        this.$refs.CpLabelsModal.hideLabelModal();
        this.$refs.CpLabelsModal.enableOk();
        this.$refs[this.tableRef].clearTableSelection();
        this.$refs[this.tableRef].updateTableData();
      });
    },
    filterLabels(massSelect = false, labelsList = []) {
      const result = {};
      labelsList.forEach((label) => {
        result[label] = this.listSelectedInvest.map(({ labels }) => (labels || []).includes(label));
        const count = result[label];
        if (!count.length) {
          Object.keys(result).forEach((el) => {
            result[el] = {};
          });
          return;
        }
        const sum = count.reduce((accum, current) => accum + current);

        if (massSelect) {
          result[label] = {
            indeterminate: true,
          };
        } else {
          result[label] = {
            checked: +sum === this.listSelectedInvest.length,
            indeterminate: sum < this.listSelectedInvest.length && sum > 0,
          };
        }
      });
      return result;
    },
    editInvestorsLabels() {
      this.modalId = 'edit-label-modal';
      this.resultFilterLabels = this.filterLabels(this.massSelect, this.issuerLabelsOnly);
      this.resultFilterPredefinedLabels = this.filterLabels(this.massSelect, this.predefinedLabels);
      this.isCheckboxActive = false;
      this.hideCreateLabel = false;
      this.$refs.CpLabelsModal.showLabelModal();
    },
    showFilterLabelsModal() {
      this.resultFilterLabels = this.setFilterModalLabels(this.issuerLabelsOnly);
      this.resultFilterPredefinedLabels = this.setFilterModalLabels(this.predefinedLabels);
      this.modalId = 'filter-label-modal';
      this.isCheckboxActive = true;
      this.hideCreateLabel = true;
      this.$refs.CpLabelsModal.showLabelModal();
    },
    setFilterModalLabels(labels = []) {
      const activeFilterLabels = (this.tableFilters.labels || '').split(',');
      const result = {};
      labels.forEach((label) => {
        result[label] = {
          checked: activeFilterLabels.includes(label),
        };
      });
      return result;
    },
    selectAllInvestors({ allSelect, selectedItems }) {
      this.listSelectedInvest = allSelect ? selectedItems : [];
    },
    selectInvestor({ value, item }) {
      if (value) {
        this.listSelectedInvest.push(item);
        return;
      }
      const index = this.listSelectedInvest.findIndex(el => el.id === item.id);
      this.listSelectedInvest.splice(index, 1);
    },

    // investors lists
    saveSort(sort) {
      this.SET_INVESTOR_SORTING({ type: this.investorsType.view, sorting: this.tableSorting(sort) });
    },
    exportInvestorsList() {
      const { items, queryParams, filters } = this.$refs[this.tableRef].$refs.cpTable;
      const fileName = `${this.currentIssuer.name}_${this.investorsType.view}_${this.dateAndTimeExport}_data`;

      const joinData = {
        ...queryParams,
        ...filters,
      };

      const filteredParams = Object.keys(joinData).reduce((res, key) => {
        if (joinData[key] !== '') {
          res[key] = joinData[key];
        }
        return res;
      }, {});
      if (items.length) {
        this.exportList({
          fileName: fileName.split(' ').join('_'),
          exportData: {
            ...this.investorsType,
            ...filteredParams,
          },
        });
      }
    },
    handlerRemoveInvestor(item) {
      this.deleteItem = item;
      this.$refs.removeInvestModal.show();
    },
    deleteIsApproved(params) {
      this.deleteInvestorByUserId(params).then(() => {
        this.$refs[this.tableRef].updateTableData();
      });
    },
    importInvestors() {
      this.$refs[this.tableRef].updateTableData();
    },
    updateFilter(data) {
      this.tableFilters = data;
    },
    handleMassSelect(massSelect) {
      this.massSelect = massSelect;
    },
  },
  watch: {
    $route(to) {
      Cookie.set(`${this.investorsType.view}-search`, to.query.search);
    },
  },
};
