export default (filterData) => {
  // split value if value is array
  const data = { ...filterData };
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key]) && key !== 'issuer-list') {
      const [first, second] = data[key];
      data[key.replace('from', 'to')] = second;
      data[key] = first;
    }
  });
  return data;
};
