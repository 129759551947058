<template>
  <div>
    <cp-button
      size
      variant="outline-info mr-3"
      @click="openModal"
    >
      <i class="ion ion-md-arrow-up" />
      {{ $t('holders.importIssuance.button') }}
    </cp-button>

    <cp-import-issuance
      ref="cpImportIssuance"
      @onImport="handleImport"
    />
    <cp-confirm-modal
      ref="cpImportIssuanceInProgress"
      :title="$t('holders.importIssuance.button')"
      ok-only
    >
      {{ recordsWithErrors>0?
        $t('holders.importIssuance.processStartedMessageWithErrors', [totalRecords, processableRecords, recordsWithErrors]):
        $t('holders.importIssuance.processStartedMessage', [totalRecords, processableRecords])
      }}
    </cp-confirm-modal>
    <cp-confirm-modal
      ref="cpImportIssuanceError"
      :title="$t('holders.importIssuance.button')"
      ok-only
    >
      {{ $t('holders.importIssuance.processCouldNotBeStartedMessage', [errorMessage]) }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CpImportIssuance from '~/pages/_idIssuer/investors/modals/import-issuance-modal';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'ImportIssuance',
  components: {
    CpImportIssuance,
    CpButton,
    CpConfirmModal,
  },
  data() {
    return {
      errorList: [],
      errorName: '',
      errorMessage: '',
      totalRecords: null,
      processableRecords: null,
      recordsWithErrors: null,
    };
  },
  computed: {
    ...mapState('issuersInfo', ['currentToken']),
    tokenId() {
      return this.$route.params.tokenId || this.currentToken.id;
    },
  },
  methods: {
    ...mapActions('importIssuance', ['setIsModalOpen', 'setIsSendingCsv', 'executeImport']),
    openModal() {
      this.$refs.cpImportIssuance.$refs.importIssuance.show();
      this.setIsModalOpen(true);
    },
    handleImport({ fileKey, forceIssuanceToTBE }) {
      this.executeImport({
        issuerId: this.$route.params.idIssuer,
        tokenId: this.tokenId,
        fileKey,
        forceIssuanceToTBE,
      }).then(({ status, processableRecords, totalRecords, recordsWithErrors }) => {
        this.errorList = [];
        this.errorName = '';
        if (status === 'ok') {
          this.totalRecords = totalRecords;
          this.recordsWithErrors = recordsWithErrors?.length ?? 0;
          this.processableRecords = processableRecords;
          this.$refs.cpImportIssuanceInProgress.show();
        }
        this.$emit('importIssuanceCreated');
      }).catch(({ response }) => {
        this.errorMessage = response?.data?.message || response?.data?.error || '';
        this.$refs.cpImportIssuanceError.show();
      }).finally(() => {
        this.$refs.cpImportIssuance.$refs.importIssuance.hide();
        this.setIsSendingCsv(false);
      });
    },
  },
};
</script>
