// eslint-disable-next-line import/prefer-default-export
export const StatusImportInvestors = {
    PROCESSING: 'Processing',
    COMPLETED_WITH_ERRORS: 'Completed with errors',
    COMPLETED_SUCCESSFULLY: 'Completed succesfully',
    NO_IMPORT_IN_PROGRESS: 'No import in progress',
};

export const StatusImportIssuance = {
    PROCESSING: 'Processing',
    COMPLETED_WITH_ERRORS: 'Completed with errors',
    COMPLETED_SUCCESSFULLY: 'Completed succesfully',
    NO_IMPORT_IN_PROGRESS: 'No import in progress',
};
