<template>
  <div>
    <b-modal
      :id="modalId"
      ref="LabelsModal"
      body-class="p-0"
      modal-class="label-model-overwrite"
      size="sm"
      :ok-title="$t('labelModal.button.ok')"
      :cancel-title="$t('common.button.cancel')"
      ok-variant="primary"
      cancel-variant="default"
      no-close-on-esc
      :ok-disabled="disableApply()"
      @ok="applyLabels"
      @hide="$emit('onHide')"
      @shown="modalRender"
    >
      <div
        slot="modal-title"
      >
        <div>
          <h5 class="my-0">
            <span class="ion ion-ios-pricetag" />
            {{ $t(`labelModal.title.${titleName}`) }}
          </h5>
        </div>
        <div class="py-2 px-4">
          <!-- Search -->
          <label class="nav-item navbar-text navbar-search-box p-0 active">
            <i class="ion ion-ios-search navbar-icon align-middle" />
            <span class="navbar-search-input pl-2">
              <b-input
                v-model="search"
                class="form-control navbar-text mx-2"
                :placeholder="$t('common.placeholder.search')"
                style="width:200px"
                @input="searchHandler"
              />
            </span>
          </label>
        </div>
        <hr class="my-0 mx-0">
        <div
          style="overflow: auto"
          class="py-3 px-4 d-flex flex-column cp-labels-list"
        >
          <b-form-checkbox
            v-for="(label, key, index) in resultFilterLabels"
            :key="index"
            v-model="label.checked"
            :indeterminate="label.indeterminate"
            :disabled="!selectedInvest"
            class="mb-1"
          >
            <div class="cp-custom-label">
              <span>{{ key }}</span>
              <b-btn
                v-if="showDeleteButton(key)"
                variant="default edit-operator-btn btn-xs md-btn-flat"
                :title="$t('common.button.delete')"
                :disabled="selectedInvest"
                @click="openConfirmModal(key)"
              >
                <i class="ion ion-ios-trash" />
              </b-btn>
            </div>
          </b-form-checkbox>
        </div>
        <hr class="my-0 mx-0">
        <div>
          <p class="my-0 pt-3 font-weight-normal">
            {{ $t('labelModal.subTitle') }}
          </p>
        </div>
        <div
          style="overflow: auto"
          class="pt-3 px-4 d-flex flex-column cp-labels-list"
        >
          <b-form-checkbox
            v-for="(label, key, index) in predefinedLabels"
            :key="index"
            v-model="label.checked"
            :indeterminate="label.indeterminate"
            :disabled="!selectedInvest"
            class="mb-1"
          >
            <span>{{ key }}</span>
          </b-form-checkbox>
        </div>
      </div>
      <cp-input-container
        v-if="!hideCreateLabel"
        class="bg-light py-3 px-4"
      >
        <div class="label-input-wrap">
          <cp-input
            v-model="newLabel"
            name="createLabel"
            validate="excluded_value:,"
            :class="{ 'border border-danger': errorField }"
            :placeholder="$t('labelModal.placeholder.labelName')"
          />
        </div>
        <div class="create-wrap">
          <span>
            <cp-button
              variant="default"
              class="button-reset"
              :disabled="disableCreate()"
              @click="createNewLabel"
            >
              <i class="ion ion-ios-add-circle-outline" />
              {{ $t('labelModal.button.label.create') }}
            </cp-button>
          </span>
        </div>
      </cp-input-container>
    </b-modal>
    <cp-confirm-modal
      v-if="!hideCreateLabel"
      ref="cpConfirmModal"
      :title="$t('deleteLabelConfirmModal.title')"
      class="bg-light py-3 px-4"
      :item="currentLabelToDelete"
      @onOk="deleteLabel"
    >
      {{ $t('deleteLabelConfirmModal.messagePartOne') }} <strong>{{ currentLabelToDelete.labelName }}</strong>.
      <br>
      {{ $t('deleteLabelConfirmModal.messagePartTwo') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import { CpInput } from '~/components/common/standalone-components/inputs';
import cpInputContainer from '~/components/common/cpInputContainer';
import CpButton from '@/components/common/standalone-components/cp-button';

export default {
  name: 'LabelModal',
  components: {
    CpButton,
    CpConfirmModal,
    cpInputContainer,
    CpInput,
  },
  props: {
    resultFilterLabels: {
      type: Object,
      default: () => {},
    },
    modalId: {
      type: String,
      required: true,
      validator: value => ['edit-label-modal', 'filter-label-modal'].indexOf(value) !== -1,
    },
    hideCreateLabel: {
      type: Boolean,
      default: false,
    },
    predefinedLabels: {
      type: Object,
      default: () => {},
    },
    errorField: {
      type: Boolean,
      default: false,
    },
    selectedInvest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      newLabel: '',
      saveStartState: null,
      isDisabled: false,
      currentLabelToDelete: {},
    };
  },
  computed: {
    ...mapState('investors', ['labelsListOriginal']),
    withoutChanges() {
      return ((JSON.stringify(this.resultFilterLabels) + JSON.stringify(this.predefinedLabels)) === this.saveStartState);
    },
    titleName() {
      const titleName = {
        'edit-label-modal': 'edit',
        'filter-label-modal': 'show',
      };
      return titleName[this.modalId];
    },
  },
  methods: {
    searchHandler: _.debounce(function () { // eslint-disable-line func-names
      this.$emit('searchLabel', this.search);
    }, 500),
    createNewLabel() {
      this.$emit('onCreateLabel', this.newLabel);
      this.newLabel = '';
    },
    showDeleteButton(key) {
      const originalLabels = [...this.labelsListOriginal];
      return (originalLabels && originalLabels.includes(key)) && this.modalId === 'edit-label-modal';
    },
    modalRender() {
      this.newLabel = '';
      this.saveStartState = JSON.stringify(this.resultFilterLabels) + JSON.stringify(this.predefinedLabels);
    },
    applyLabels(env) {
      env.preventDefault();
      let emitEvent = 'onApplyEditLabels';
      if (env.componentId === 'edit-label-modal' && this.withoutChanges) return;
      if (env.componentId === 'filter-label-modal') emitEvent = 'onApplyFilterLabels';
      this.$emit(emitEvent, { ...this.resultFilterLabels, ...this.predefinedLabels });
    },
    disableCreate() {
      return this.newLabel.includes(',');
    },
    disableOk() {
      this.isDisabled = true;
    },
    disableApply() {
      return !this.selectedInvest && (this.modalId === 'edit-label-modal');
    },
    enableOk() {
      this.isDisabled = false;
    },
    showLabelModal() {
      this.$refs.LabelsModal.show();
    },
    hideLabelModal() {
      this.$refs.LabelsModal.hide();
    },
    openConfirmModal(label) {
      this.currentLabelToDelete = { labelName: label };
      this.$refs.cpConfirmModal.show();
    },
    deleteLabel() {
      this.$emit('onDeleteLabel', this.currentLabelToDelete);
      this.currentLabelToDelete = {};
      this.$refs.cpConfirmModal.$refs.confirmModal.hide();
      this.hideLabelModal();
    },
  },
};
</script>

<style lang="scss">
  .label-model-overwrite{
    .modal-header{
      .close {
        top: 30px;
      }
    }
  }
    .cp-labels-list {
        max-height: 200px;
        overflow: auto;
    }
  .create-wrap {
    width: 28%;
    display: inline-block;
    vertical-align: top;
  }
  .label-input-wrap {
    width: 70%;
    display: inline-block;
  }
  .button-reset {
    height: 38px;
    margin-top: 0;
  }
  .cp-custom-label {
    display: flex;
    justify-content: space-between;
    width: 208px;
  }
</style>
